function showhide(id){
if (document.getElementById){
obj = document.getElementById(id);
if (obj.style.display == "none"){
obj.style.display = "";
} else {
obj.style.display = "none";
}
}
}

function changecss(theClass,element,value) {
 var cssRules;
 if (document.all) {
  cssRules = 'rules';
 }
 else if (document.getElementById) {
  cssRules = 'cssRules';
 }
 var added = false;
 for (var S = 0; S < document.styleSheets.length; S++){
  for (var R = 0; R < document.styleSheets[S][cssRules].length; R++) {
   if (document.styleSheets[S][cssRules][R].selectorText == theClass) {
	if(document.styleSheets[S][cssRules][R].style[element]){
	document.styleSheets[S][cssRules][R].style[element] = value;
	added=true;
	break;
	}
   }
  }

  if(!added){
  if(document.styleSheets[S].insertRule){
		  document.styleSheets[S].insertRule(theClass+' { '+element+': '+value+'; }',document.styleSheets[S][cssRules].length);
		} else if (document.styleSheets[S].addRule) {
			document.styleSheets[S].addRule(theClass,element+': '+value+';');
		}
  }
 }
}